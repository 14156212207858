import React from "react"
import AffiliatePage from '../components/pages/AffiliatePage'
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout"

const AffiliateProgramPage = props => {
  if (typeof window === "undefined") return null
  return <AffiliatePage {...props} />
}

AffiliateProgramPage.propTypes = {}

AffiliateProgramPage.defaultProps = {}

const AffiliateProgramPagePage = props => {
  if (typeof window === "undefined") return null
  return (
    <UnauthenticatedLayout>
      <AffiliateProgramPage {...props} />
    </UnauthenticatedLayout>
  )
}

export default AffiliateProgramPagePage
